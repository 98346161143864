import React, { useState } from 'react';
import moment from 'moment';
import './TruckForm.css'; // Ensure you have the correct CSS file path

const TruckForm = ({ truck, onUpdate }) => {
  const [formData, setFormData] = useState(truck);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: parseFloat(value) // Ensuring numeric values are handled correctly
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onUpdate(truck.id, formData);
  };

  return (
    <form className="truck-form" onSubmit={handleSubmit}>
      <h3>{truck.internal_name}</h3>
      <label>Width (feet):
        <input type="number" name="width" value={formData.width} onChange={handleChange} />
      </label>
      <label>Tank Size:
        <input type="number" name="gas_tank_size" value={formData.gas_tank_size} onChange={handleChange} />
      </label>
      <label>Default MPG:
        <input type="number" name="mpg" value={formData.mpg} onChange={handleChange} />
      </label>
      <label>{truck.automatic_pull ? 'Current Percent(0-100)' : 'Default Start Percent(0-100)'}:
        <input type="number" name="percent_left" value={formData.percent_left} onChange={handleChange} disabled={truck.automatic_pull} />
      </label>
      {truck.automatic_pull && (
        <div>
          <label>Live Location:</label>
          <p>Longitude: {truck.live_location.lon}</p>
          <p>Latitude: {truck.live_location.lat}</p>
          <label>Last Checked:</label>
          <p>{moment(truck.timestamp * 1000).fromNow()}</p>
        </div>
      )}
      <button type="submit">Save Changes</button>
    </form>
  );
};

export default TruckForm;