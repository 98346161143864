import React, { createContext, useContext } from 'react';
import useToken from '../hooks/useToken';

// Create a context for the token
export const TokenContext = createContext();

// Create a provider component that uses the useToken hook
export function TokenProvider({ children }) {
  const { token, setToken } = useToken();
  return (
    <TokenContext.Provider value={{ token, setToken }}>
      {children}
    </TokenContext.Provider>
  );
}

// Create a custom hook to use the token context
export function useTokenContext() {
  return useContext(TokenContext);
}