import React from 'react';

const RouteSegment = ({ address, type, fillUp, total_fuel, distance, rank, isLastStation, pricePG, isOpen }) => {
  const iconMap = {
    start: "🏁",
    station: "⛽",
    end: "🏠"
  };
  if (type === "station") {
    if (fillUp >  0) {
      fillUp = fillUp.toFixed(0)
    } else {
      fillUp = "Optional"
    }
  }


  
  return (
    <div className={`route-segment ${type} ${isLastStation ? 'last-station' : ''}`}>
      <span className="route-icon">{iconMap[type]}</span>
      
      
        <div className="route-info">
          <p className="route-address">{address}</p>
          {isOpen && (
            <div>
              {(distance !== null && distance !== undefined) && <p className="route-distance">Distance: {distance.toFixed(0)} mi</p>}
              {pricePG && <p className="route-fillup">Price Per Gallon: ${pricePG.toFixed(3)}</p>}
              {rank && <p className="route-fillup">Rank: #{rank}</p>}
              {fillUp && <p className="route-fillup">Fill: {fillUp} gallons</p>}
              {total_fuel && <p className="route-fillup">Total Fuel: {total_fuel.toFixed(0)} gallons</p>}
              {total_fuel && pricePG && fillUp && <p className="route-fillup">Total Cost: ${(Number(fillUp).toFixed(0) * pricePG.toFixed(3)).toFixed(2)}</p>}
            </div>
          )}
        </div>
      
    </div>
  );
};

export default RouteSegment;
