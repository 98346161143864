import React, { useState } from 'react';

const PasswordChangeForm = () => {
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPasswords(prevPasswords => ({
      ...prevPasswords,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (passwords.newPassword !== passwords.confirmNewPassword) {
      alert("New passwords do not match!");
      return;
    }
    console.log('Password Change Requested:', passwords);
    // Call an API to change the password here
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Current Password:
        <input type="password" name="currentPassword" value={passwords.currentPassword} onChange={handleChange} disabled />
      </label>
      <label>
        New Password:
        <input type="password" name="newPassword" value={passwords.newPassword} onChange={handleChange} disabled />
      </label>
      <label>
        Confirm New Password:
        <input type="password" name="confirmNewPassword" value={passwords.confirmNewPassword} onChange={handleChange} disabled />
      </label>
      <button type="submit">Change Password</button>
    </form>
  );
};

export default PasswordChangeForm;
