import React, { createContext, useState, useEffect, useContext } from 'react';
import Popup from '../components/Popup';

export const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const [popup, setPopup] = useState({ show: false, message: '', color: '' });

  const showPopup = (message, color) => {
    setPopup({ show: true, message, color });
  };

  useEffect(() => {
    if (popup.show) {
      const timer = setTimeout(() => setPopup({ show: false, message: '', color: '' }), 3000);
      return () => clearTimeout(timer);
    }
  }, [popup.show]);

  return (
    <PopupContext.Provider value={{ showPopup }}>
      {children}
      <Popup show={popup.show} message={popup.message} color={popup.color} />
    </PopupContext.Provider>
  );
};

export function usePopupContext() {
    return useContext(PopupContext);
}