import React, { useState } from 'react';

const PreferencesForm = () => {
  const [preferences, setPreferences] = useState({
    emailNotifications: true,
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setPreferences(prevPreferences => ({
      ...prevPreferences,
      [name]: checked
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Preferences Updated:', preferences);
    // Typically, here you would call an API to update the user preferences
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Email Notifications:
        <input
          type="checkbox"
          name="emailNotifications"
          checked={preferences.emailNotifications}
          onChange={handleChange}
          disabled
        />
      </label>
      <button type="submit">Save Preferences</button>
    </form>
  );
};

export default PreferencesForm;
