export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function fetchWithToken(url, token, setToken, options = {}) {
    // Add the token to the request headers
    options.headers = {
      ...options.headers,
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
  
    return fetch(url, options)
      .then(response => {
        if (response.status === 401) {
            setToken(null);
            return null;
        }
        return response.json();
      });
  }