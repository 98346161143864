import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import Sidebar from './Sidebar';
import RoutesPage from './pages/RoutesPage';
import ReportsPage from './pages/ReportsPage';
import GasStationsPage from './pages/GasStationsPage';
import Login from './pages/Login';
import TruckSettings from './pages/TruckSettings';
import AccountSettings from './pages/AccountSettings';
import CompanySettings from './pages/CompanySettings';
import DashboardPage from './pages/DashboardPage';
import { API_ENDPOINT } from './api';
import { useTokenContext } from './contexts/TokenContext';
import { usePopupContext } from './contexts/PopupContext';
import './App.css';



function App() {
  const { token, setToken } = useTokenContext();
  const { showPopup } = usePopupContext();

  const handleLogin = (username, password) => {
    fetch(`${API_ENDPOINT}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      showPopup('Logged in Successfully!', 'green');
      setToken(data);
    })
    .catch((error) => {
      showPopup('Bad Password', 'red');
      console.error('Error:', error);
    });
  };
  if(!token) {
    return <Login onLogin={handleLogin} />
  }

  return (
    <Router>
      <div className="App">
        <Sidebar />
        <div className="content">
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/routes" element={<RoutesPage />} />
            <Route path="/stations" element={<GasStationsPage />} />
            <Route path="/reports-page" element={<ReportsPage />} />
            <Route path="/truck-settings" element={<TruckSettings />} />
            <Route path="/company-settings" element={<CompanySettings />} />
            <Route path="/account-settings" element={<AccountSettings />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
