import React, { useState, useEffect } from 'react';
import StationsMap from '../components/StationsMap';
import fetchWithToken from '../api';
import { useTokenContext } from '../contexts/TokenContext';
import { API_ENDPOINT } from '../api';

const GasStationsPage = () => {
  const { token, setToken } = useTokenContext();
  const [stations, setStations] = useState([]);

  useEffect(() => {
    fetchWithToken(`${API_ENDPOINT}/getgasstations`, token, setToken)
      .then(data => {
        setStations(data || []);
      })
      .catch(error => {
        console.error('Error fetching gas stations:', error);
        setStations([]);
      });
  }, [token, setToken]);

  return (
    <div>
      <StationsMap stations={stations} />
    </div>
  );
};

export default GasStationsPage;