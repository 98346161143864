import React from 'react';
import './Popup.css'; // assuming you have a Popup.css file with the styles

function Popup({ show, message, color }) {
  const classes = `popup ${color} ${show ? 'show' : ''}`;

  return (
    <div className={classes}>
      {message}
    </div>
  );
}

export default Popup;