import React, { useState, useEffect } from 'react';
import fetchWithToken from '../api';
import { API_ENDPOINT } from '../api';
import { useTokenContext } from '../contexts/TokenContext';
import { usePopupContext } from '../contexts/PopupContext';

const CompanyInfoForm = () => {
  const { token, setToken } = useTokenContext();
  const { showPopup } = usePopupContext();

  const [companyInfo, setCompanyInfo] = useState({
    name: '',
    route_config: {
      threshold: '',
      min_station_percent: '',
      min_destination_percent: '',
      min_need_to_fill_up: '',
      full_fill_on_cheapest: true,
      full_fill_on_only: true,
      full_fill_after_gallons: '',
      require_a_stop: false,
    }
  });

  useEffect(() => {
    fetchWithToken(`${API_ENDPOINT}/getcompany`, token, setToken)
      .then(data => {
        if (data.error && data.error === 'Failed to get Company') {
          console.error('Error:', data.error);
          setCompanyInfo([]);
        } else {
          console.log(data);
          setCompanyInfo(data || []);
        }
      })
      .catch(error => {
        console.error('Error fetching company:', error);
        setCompanyInfo([]);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleChange = (event) => {
    const { name, type, min, max } = event.target;
    let value;
    
    if (type === 'checkbox') {
      value = event.target.checked;
    } else {
      value = event.target.value;
      const isNumber = !isNaN(value);
      value = isNumber ? parseFloat(value) : value;
      if (isNumber) {
        if (max && value > max) {
          value = max;
        }
        if (min && value < min) {
          value = min;
        }
      }
    }
  
    setCompanyInfo(prevInfo => {
      if (name in prevInfo) {
        return { ...prevInfo, [name]: value };
      } else if (name in prevInfo.route_config) {
        return { 
          ...prevInfo, 
          route_config: { ...prevInfo.route_config, [name]: value }
        };
      }
      return prevInfo;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchWithToken(`${API_ENDPOINT}/getcompany/update`, token, setToken, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(companyInfo)
        })
        .then(data => {
          if (!data) {
            throw new Error('Network response was not ok');
          }
          showPopup('Company info saved!', 'green');
        })
        .catch(error => {
          showPopup('Error saving company info!', 'red');
          console.error('Error fetching company:', error);
          setCompanyInfo([]);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        <h3>Name:</h3>
        <div style={{backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px'}}>
          <small>The name of the company.</small>
        </div>
        <input type="text" name="name" value={companyInfo.name} onChange={handleChange} disabled={true} />
      </label>
      <label data-sub="miles">
        <h3>Max Gas Station Distance From Route:</h3>
        <div style={{backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px'}}>
          <small>The max distance in miles a gas station can be picked from the route.</small>
        </div>
        <input type="number" style={{width: "50px"}} step="1" min="0" name="threshold" value={companyInfo.route_config.threshold} onChange={handleChange} />
        <span> miles</span>
      </label>
      <label>
        <h3>Minimum Needed To Pick: </h3>
        <div style={{backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px'}}>
          <small>The minimum needed gallons to stop at this station. Meaning that with a value of 5 gallons, the route planner will skip any stations that require 5 or less gallons and attempt to offload the missing to the next or previous station. This may result in reaching a station out of fuel if higher then minimum needed to reach a gas station.</small>
        </div>
        <input type="number" style={{width: "50px"}} step="1" min="1" name="min_need_to_fill_up" value={companyInfo.route_config.min_need_to_fill_up} onChange={handleChange} />
        <span> gallons</span>
      </label>
      <label>
        <h3>Minimum to Reach Stations</h3>
        <div style={{backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px'}}>
          <small>The minimum percent from 0% - 99% to route plan where the truck will stop with x% gas. Setting to 10 will mean that the route will plan for the truck to stop at each station with at least 10%</small>
        </div>
        <input type="number" style={{width: "50px"}} step="1" min="1" max="99" name="min_station_percent" value={companyInfo.route_config.min_station_percent} onChange={handleChange} />
        <span> %</span>
      </label>
      <label>
        <h3>Minimum to Reach Destinations:</h3>
        <div style={{backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px'}}>
          <small>The minimum percent from 0% - 99% to route plan where the truck will stop with x% gas. Setting to 25 will mean that the route will plan for the truck to stop at each destination with at least 25%</small>
        </div>
        <input type="number" style={{width: "50px"}} step="1" min="0" max="99" name="min_destination_percent" value={companyInfo.route_config.min_destination_percent} onChange={handleChange} />
        <span> %</span>
      </label>
      <label>
        <h3>Full Fill after X Gallons:</h3>
        <div style={{backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px'}}>
          <small>When the number of gallons to fill up is this number or greater, it will automatically default to say full fill. Can be disabled by setting to a number higher then the highest truck tank size in your fleet or set to a very large number.</small>
        </div>
        <input type="number" style={{width: "50px"}} step="1" min="0" name="full_fill_after_gallons" value={companyInfo.route_config.full_fill_after_gallons} onChange={handleChange} />
        <span> gallons</span>
      </label>
      <label>
        <h3>Full Fill on Only Station:</h3>
        <div style={{backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px'}}>
          <small>When checked, the route plan will always say to fill up to full if it is the only station along the route.</small>
        </div>
        <input type="checkbox" name="full_fill_on_only" checked={companyInfo.route_config.full_fill_on_only} onChange={handleChange} />
      </label>
      <label>
        <h3>Full Fill on Cheapest:</h3>
        <div style={{backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px'}}>
          <small>When checked, the route plan will always say to fill up to full if it is the cheapest station along the route.</small>
        </div>
        <input type="checkbox" name="full_fill_on_cheapest" checked={companyInfo.route_config.full_fill_on_cheapest} onChange={handleChange} />
      </label>
      <label>
        <h3>Require a Stop:</h3>
        <div style={{backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px'}}>
          <small>When checked, even if it is possible to complete the whole route without stopping, will force a station to be choosen to fill up enough to cover distance driven. In multi stop routes, stops may get condensed to reduce stops. </small>
        </div>
        <input type="checkbox" name="require_a_stop" checked={companyInfo.route_config.require_a_stop} onChange={handleChange} />
      </label>
      <button type="submit">Save Changes</button>
    </form>
  );
};

export default CompanyInfoForm;