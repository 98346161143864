import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaRoute, FaTruck, FaUserCog, FaSignOutAlt, FaGasPump, FaBuilding, FaTable } from 'react-icons/fa';
import './Sidebar.css'; // Ensure this file is linked correctly
import { useTokenContext } from './contexts/TokenContext';


const Sidebar = () => {
  const { setToken } = useTokenContext();
  function logout() {
    // remove the token from local storage
    localStorage.removeItem('token');

    setToken(null);
  }

  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <img src="/logo.webp" alt="Logo" />
      </div>
      <div className="sidebar-links">
        <Link to="/"><FaHome /><span className="nav-text">Dashboard</span></Link>
        <Link to="/routes"><FaRoute /><span className="nav-text">Routes</span></Link>
        <Link to="/stations"><FaGasPump /><span className="nav-text">All Stations</span></Link>
        <Link to="/reports-page"><FaTable /><span className="nav-text">Reports</span></Link>
        <Link to="/truck-settings"><FaTruck /><span className="nav-text">Truck Settings</span></Link>
        <Link to="/company-settings"><FaBuilding /><span className="nav-text">Company Settings</span></Link>
        <Link to="/account-settings"><FaUserCog /><span className="nav-text">Account Settings</span></Link>
      </div>
      <div className="sidebar-logout">
        <Link to="/routes" onClick={logout}><FaSignOutAlt /><span className="nav-text">Logout</span></Link>
      </div>
    </div>
  );
};

export default Sidebar;
