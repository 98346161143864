import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useTokenContext } from '../contexts/TokenContext';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend} from 'chart.js';
import fetchWithToken from '../api';
import { API_ENDPOINT } from '../api';

import './DashboardPage.css';

function DashboardPage() {
  const { token, setToken } = useTokenContext();
  const [numReports, setNumReports] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalMiles, setTotalMiles] = useState(0);
  const [totalGallons, setTotalGallons] = useState(0);
  const [averageMonthlyDieselPrice, setAverageMonthlyDieselPrice] = useState(0);
  const [chartData, setChartData] = useState([]);
  const labels = useMemo(() => ['6 Days ago', '5 Days ago', '4 Days ago', '3 Days ago', '2 Days ago', '1 Day ago', 'Today'], []);

  ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend);
  const chartRef = useRef(null); // Create a ref to keep track of the chart instance

  useEffect(() => {
    fetchWithToken(`${API_ENDPOINT}/getaverageprice`, token, setToken)
      .then(data => {
        if (data.error) {
          console.error('Error:', data.error);
        } else {
          setAverageMonthlyDieselPrice(parseFloat(data))
        }
      })
      .catch(error => {
        console.error('Error fetching reports:', error);
        // setReports([]);
      });

    fetchWithToken(`${API_ENDPOINT}/getreports`, token, setToken)
      .then(data => {
        if (data.error) {
          console.error('Error:', data.error);
        } else {
          console.log(data)
          setNumReports(data.length);
          const totalSavings = data.reduce((total, report) => total + report.total_cost, 0);
          setTotalCost(totalSavings);
          const totalMiles = data.reduce((total, report) => total + report.total_distance, 0);
          setTotalMiles(totalMiles);
          const totalGallons = data.reduce((total, report) => total + report.total_gallons, 0);
          setTotalGallons(totalGallons);
          const oneWeekAgo = moment().subtract(7, 'days');
          const filteredReports = data.filter(report => moment.unix(report.last_updated).isAfter(oneWeekAgo));
          const initialAcc = labels.reduce((acc, _, index) => {
            const date = moment().subtract(index, 'days').format('YYYY-MM-DD');
            acc[date] = { count: 0, totalCost: 0, totalSavings: 0 };
            return acc;
          }, {});
          const groupedReports = filteredReports.reduce((acc, report) => {
            const date = moment.unix(report.last_updated).format('YYYY-MM-DD');
            // Only include the report if it's from the last 6 days
            if (acc[date]) {
              acc[date].count += 1;
              acc[date].totalCost += report.total_cost;
              acc[date].totalSavings += report.total_gallons*averageMonthlyDieselPrice - report.total_cost;
            }
            return acc;
          }, initialAcc);
          setChartData(
            Object.entries(groupedReports)
              .sort(([dateA], [dateB]) => moment(dateA).isAfter(dateB) ? 1 : -1)
              .map(([date, { count, totalCost, totalSavings }]) => ({ date, count, totalCost, totalSavings }))
          );
        }
      })
      .catch(error => {
        console.error('Error fetching reports:', error);
        // setReports([]);
      });

      // return () => {
      //   if (chartRef.current) {
      //     chartRef.current.chartInstance.destroy(); // Destroy the previous chart instance
      //   }
      // };
  }, [token, setToken, averageMonthlyDieselPrice, labels]); 

  const calculatedGallons = averageMonthlyDieselPrice * totalGallons - totalCost;

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>
      <div className="chart-container">
        <Line
          ref={chartRef}
          data={{
            labels: labels,
            datasets: [
              {
                label: 'Number of Generated Routes',
                data: chartData.map(({ count }) => count),
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
                yAxisID: 'y2'
              },
              {
                label: 'Total Cost',
                data: chartData.map(({ totalCost }) => totalCost),
                borderColor: 'rgba(255, 99, 132, 1)',
                fill: false,
                yAxisID: 'y1'
              },
              {
                label: 'Total Savings',
                data: chartData.map(({ totalSavings }) => totalSavings),
                borderColor: 'rgba(255, 159, 64, 1)',
                fill: false,
                yAxisID: 'y1'
              }
            ]
          }}
          options={{
            responsive: true,
            interaction: {
              mode: 'index',
              intersect: false,
            },
            plugins: {
              legend: {
                display: true,
                position: 'top',
              },
            },
            scales: {
              y1:{
                  type: 'linear',
                  id: 'y1',
                  min: 0,
                  position: 'left',
                  beginAtZero: true,
                  ticks: {
                    font: {
                      size: 20,
                    },
                    callback: function(value, index, values) {
                      return '$' + value;
                    }
                  }
                  // ... other options
                },
              y2: {
                  type: 'linear',
                  id: 'y2',
                  min: 0,
                  position: 'right',
                  beginAtZero: true,
                  ticks: {
                    font: {
                      size: 20,
                    },
                    callback: function(value, index, values) {
                      return Math.floor(value);
                    }
                  }
                  // ... other options
                },
              x: {
                type: 'category', 
              }
            }
          }}
        />
      </div>
      <div className="grid-container">
        <div className="dashboard-item">
          <h2>Total Generated Routes</h2>
          <p>{numReports}</p>
        </div>
        <div className="dashboard-item">
          <h2>Total Generated Cost</h2>
          <p>${totalCost.toFixed(0)}</p>
        </div>
        <div className="dashboard-item">
          <h2>Total Generated Miles</h2>
          <p>{totalMiles.toFixed(0)} miles</p>
        </div>
        <div className="dashboard-item">
          <h2>Total Generated Gallons</h2>
          <p>{totalGallons.toFixed(0)} gallons</p>
        </div>
        <div className="dashboard-item">
          <h2>Total Savings(Based off of ${averageMonthlyDieselPrice.toFixed(3)} average)</h2>
          <p>${calculatedGallons.toFixed(0)}</p>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;