import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './Map.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = ({ route, destinations, stations, live_location }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [stillLoading, setStillLoading] = useState(true)
  const markers = useRef([]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    console.time('loadMap');
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/navigation-night-v1',
      center: [-98.35, 39.50],
      zoom: 4,
      optimize: true
    });
    map.current.on('load', () => {
      console.timeEnd('loadMap');
      setStillLoading(false);
    });
  },[]);

  useEffect(() => {
    if (!map.current || stillLoading || !route) return;

    if (map.current.getLayer('route')) {
      map.current.removeLayer('route');
    }
    if (map.current.getSource('route')) {
      map.current.removeSource('route');
    }

    markers.current.forEach(marker => marker.remove());
    markers.current = [];

    const routeCoordinates = [];
    Object.keys(route).forEach(key => {
      route[key].forEach(point => {
        routeCoordinates.push([point.lon, point.lat]); // Convert to an array of [lon, lat]
      });
    });
    
    if (!map.current.getSource('route')) {
      // Define the source and layer for the route
      map.current.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: routeCoordinates
          }
        }
      });
    }
    if (!map.current.getLayer('route')) {
      map.current.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#ff471a',
          'line-width': 5
        }
      });
    }
    // Fit the map to the bounds of the route
    const bounds = new mapboxgl.LngLatBounds();
    routeCoordinates.forEach(point => bounds.extend([point[0], point[1]]));
    map.current.fitBounds(bounds, {
      padding: 200 // or whatever padding you prefer
    });

    // map.current.once('moveend', () => {
    //   // Set the pitch back to your desired value
    //   map.current.easeTo({ pitch: 60 }, { duration: 3000 });
    // });

    

    // Add markers for destinations
    destinations.forEach(destination => {
      const lon = destination.coordinates.lon;
      const lat = destination.coordinates.lat;
      const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;
      const popupContent = `
        <p>Address:<br />
        ${destination.address1}, ${destination.address2}<br />
        ${destination.city}, ${destination.state} ${destination.zip}</p>
        <p>Coordinates: ${lat}, ${lon}</p>
        <a style="color: black; text-decoration: underline;" href="${googleMapsLink}" target="_blank">Open in Google Maps</a>
      `;
      const marker = new mapboxgl.Marker({ color: 'red' }) // change the color to differentiate from stations
        .setLngLat([lon, lat])
        .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent))
        .addTo(map.current);
      markers.current.push(marker);
    });

    Object.keys(stations).forEach(key => {
      stations[key].forEach(point => {
        const lon = point.optimal_station.coordinates.lon;
        const lat = point.optimal_station.coordinates.lat;
        const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;
        const popupContent = `
          <h3>Internal ID: ${point.optimal_station.internal_id}</h3>
          <p>Address:<br />
          ${point.optimal_station.address1}, ${point.optimal_station.address2}<br />
          ${point.optimal_station.city}, ${point.optimal_station.state} ${point.optimal_station.zip}</p>
          <p>Coordinates: ${lat}, ${lon}</p>
          <a style="color: black; text-decoration: underline;" href="${googleMapsLink}" target="_blank">Open in Google Maps</a>
        `;
        const marker = new mapboxgl.Marker({ color: 'blue' })
          .setLngLat([lon, lat])
          .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent))
          .addTo(map.current);
        markers.current.push(marker);
      });
    });

    if (live_location.lat !== 0 && live_location.lon !== 0) {
      const popup = new mapboxgl.Popup({ offset: 25 })
      .setText('Location of the truck');

      const marker = new mapboxgl.Marker({ color: 'green' })
        .setLngLat([live_location.lon, live_location.lat])
        .setPopup(popup)
        .addTo(map.current);
  
      markers.current.push(marker);
    }
        


    // Clean up the map instance when component unmounts
    // return () => {
    //   if (map.current) {
    //     map.current.remove();
    //   }
    // };
  }, [route, destinations, stations, live_location, stillLoading]); // Only re-initialize the map when these dependencies change

  return (
    <div className="map-area">
      <div className="map-legend">
        <h4>Map Legend</h4>
        <ul>
          <li><span className="legend-route"></span> Route Path</li>
          <li><span className="legend-destination"></span> Destinations</li>
          <li><span className="legend-station"></span> Stations</li>
          {(live_location.lat !== 0 && live_location.lon !== 0) && <li><span className="legend-live"></span> Truck Live Location</li>}
        </ul>
      </div>
      <div ref={mapContainer} className="map-container" style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

export default Map;