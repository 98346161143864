import React from 'react';
import './AccountSettings.css'; // Make sure to include the stylesheet
import PasswordChangeForm from '../components/PasswordChangeForm';
import PreferencesForm from '../components/PreferencesForm';

const AccountSettings = () => {
  return (
    <div className="accountSettingsMain">
      <div className="account-settings">
        <h1>Account Settings</h1>
        <h6>Currently Disabled. Contact to change settings</h6>
        {/* <section>
          <h2>Basic Information</h2>
          <BasicInfoForm />
        </section> */}
        <section>
          <h2>Change Password</h2>
          <PasswordChangeForm />
        </section>
        <section>
          <h2>Preferences</h2>
          <PreferencesForm />
        </section>
        <section>
          <h2>More Settings</h2>
          {/* Placeholder for future settings */}
          <p>More settings will be implemented here.</p>
        </section>
      </div>
    </div>
  );
};

export default AccountSettings;
