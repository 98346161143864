import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';

const StationsMap = ({ stations }) => {
      const mapContainer = useRef(null);
      const map = useRef(null);
      useEffect(() => {
        if (stations.length === 0) return;
        if (map.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/navigation-night-v1',
            center: [-98.35, 39.50],
            zoom: 4,
            optimize: true
        });

        stations.forEach(point => {
          const lon = point.coordinates.lon;
          const lat = point.coordinates.lat;
          const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;
          const popupContent = `
            <h3>Internal ID: ${point.internal_id}</h3>
            <p>Price: $${point.price}</p>
            <p>Address:<br />
            ${point.address1}, ${point.address2}<br />
            ${point.city}, ${point.state} ${point.zip}</p>
            <p>Coordinates: ${lat}, ${lon}</p>
            <a style="color: black; text-decoration: underline;" href="${googleMapsLink}" target="_blank">Open in Google Maps</a>
          `;
          new mapboxgl.Marker({ color: 'blue' })
            .setLngLat([lon, lat])
            .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent))
            .addTo(map.current);
        });

        // Clean up the map instance when component unmounts
        // return () => {
        //   if (map.current) {
        //     map.current.remove();
        //   }
        // };
      }, [stations]);

      return (
        <div className="stations-map-area">
          <div ref={mapContainer} style={{ width: '100%', height: '100%' }}></div>
        </div>
      );
    };

export default StationsMap;