import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTokenContext } from '../contexts/TokenContext';
import fetchWithToken from '../api';
import { API_ENDPOINT } from '../api';
import './ReportsPage.css';

const ReportsPage = () => {
  const { token, setToken } = useTokenContext();
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const reportsPerPage = 20;

  useEffect(() => {
    fetchWithToken(`${API_ENDPOINT}/getreports`, token, setToken)
      .then(data => {
        if (data.error) {
          console.error('Error:', data.error);
          setReports([]);
        } else {
          setReports(data || []);
        }
      })
      .catch(error => {
        console.error('Error fetching reports:', error);
        setReports([]);
      });
  }, [token, setToken]); 

  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = reports.slice(indexOfFirstReport, indexOfLastReport);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(reports.length / reportsPerPage); i++) {
    pageNumbers.push(i);
  }

  // In your ReportsPage.js
    return (
        <div className="reports-page">
          <table className="reports-table">
            <thead>
              <tr>
                <th>Last Updated</th>
                <th>Description</th>
                <th>Total Cost</th>
                <th>Total Distance</th>
              </tr>
            </thead>
            <tbody>
            {currentReports.map(report => (
                <tr key={report.id}>
                  <td>{moment(report.last_updated * 1000).fromNow()}</td>
                  <td>{report.custom_description}</td>
                  <td>${report.total_cost.toFixed(0)}</td>
                  <td>{report.total_distance.toFixed(0)} miles</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
              {pageNumbers.map(number => (
                <button
                  key={number}
                  onClick={() => setCurrentPage(number)}
                  className={`page-number ${currentPage === number ? 'active' : ''}`}
                >
                  {number}
                </button>
              ))}
            </div>
        </div>
    );
};

export default ReportsPage;