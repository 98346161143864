import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        if (tokenString && tokenString !== "undefined") {
          try {
            const userToken = JSON.parse(tokenString);
            return userToken.token;
          } catch (error) {
            console.error('Invalid token:', tokenString);
            localStorage.removeItem('token');
          }
        }
        return null;
    };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    if (userToken === null) {
      localStorage.removeItem('token');
      setToken(null);
      return;
    }
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token
  }
}