import React from 'react';
import CompanyInfoForm from '../components/CompanyInfoForm';

const CompanySettings = () => {
  return (
    <div className="accountSettingsMain">
      <div className="account-settings">
        <h1>Company Settings</h1>
        <section>
          <CompanyInfoForm />
        </section>
      </div>
    </div>
  );
};

export default CompanySettings;
