import React, { useState, useEffect } from 'react';
import TruckForm from '../components/TruckForm'; 
import './TruckSettings.css'; 
import fetchWithToken from '../api';
import { useTokenContext } from '../contexts/TokenContext';
import { usePopupContext } from '../contexts/PopupContext';

import { API_ENDPOINT } from '../api';

const TruckSettings = () => {
  const [trucks, setTrucks] = useState([]);
  const { token, setToken } = useTokenContext();
  const [searchTerm, setSearchTerm] = useState("");
  const { showPopup } = usePopupContext();


  useEffect(() => {
    fetchWithToken(`${API_ENDPOINT}/gettrucks`, token, setToken)
      .then(data => {
        setTrucks(data || []);        
      })
      .catch(error => {
        console.error('Error fetching routes:', error);
        setTrucks([]);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleUpdate = (id, updatedTruck) => {
    fetchWithToken(`${API_ENDPOINT}/gettrucks/${id}/update`, token, setToken, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedTruck)
    })
    .then(data => {
      if (!data) {
        throw new Error('Network response was not ok');
      }
      showPopup('Truck saved!', 'green');
    })
    .catch(error => {
      showPopup('Error saving truck!', 'red');

      console.error('Error updating route:', error);
    });
  };

  return (
    <div>
      <input className="search-input" type="text" placeholder="Search by truck name" value={searchTerm} onChange={event => setSearchTerm(event.target.value)} />

      <div className="truck-settings-container">
        {trucks.filter(truck => truck.internal_name.toLowerCase().includes(searchTerm.toLowerCase())).map(truck => (
          <TruckForm key={truck.id} truck={truck} onUpdate={handleUpdate} />
        ))}
      </div>
    </div>
  );
};

export default TruckSettings;
