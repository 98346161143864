import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './RoutesPage.css';
import RouteMap from '../components/RouteMap';
import RouteSegment from '../components/RouteSegment';
import { useTokenContext } from '../contexts/TokenContext';
import { usePopupContext } from '../contexts/PopupContext';
import fetchWithToken from '../api';
import { API_ENDPOINT } from '../api';

const RoutesPage = () => {
  const { token, setToken } = useTokenContext();
  const { showPopup } = usePopupContext();
  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [allRoutes, setAllRoutes] = useState([]);
  const [activeRoute, setActiveRoute] = useState(null);
  const [openSegments, setOpenSegments] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [liveMessage, setLiveMessage] = useState('');

  const handleOpenModal = () => {
    setLoading(prevLoading => ({ ...prevLoading, [activeRoute.id]: true }));
    getMessage(activeRoute.internal_id);
  };

  const handleLiveOpenModal = () => {
    setLoading(prevLoading => ({ ...prevLoading, [activeRoute.id]: true }));
    getLiveMessage(activeRoute.internal_id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const toggleSegment = (key) => {
    setOpenSegments(prevState => ({ ...prevState, [key]: !prevState[key] }));
  };
  
  const toggleActiveRoute = (route) => {
    setLoading(prevLoading => ({ ...prevLoading, [route.id]: true }));

    fetchWithToken(`${API_ENDPOINT}/getroutes/${route.id}`, token, setToken)
      .then(data => {
        fetchWithToken(`${API_ENDPOINT}/gettrucks/${data.truck_id}`, token, setToken)
        .then(data2 => {
          if (data2.live_location) {
            data.live_location = data2.live_location;
          }
          setActiveRoute(data);
          setOpenSegments({});
          setTimeout(() => {
            document.getElementById(`route-card-${route.id}`).scrollIntoView({ behavior: 'smooth' });
          }, 200);
          })
        .catch(error => {
          console.error('Error fetching truck details:', error);
        })
      })
      .catch(error => {
        console.error('Error fetching route details:', error);
      })
      .finally(() => {
        setLoading(prevLoading => ({ ...prevLoading, [route.id]: false }));
      });
  };
  
  function regenerateRoute(id) {
    fetchWithToken(`${API_ENDPOINT}/getroutes/${id}/regenerate`, token, setToken)
    .then(data => {
      if (!data) {
        throw new Error('Network response was not ok');
      }
      showPopup('Sent request to regenerate route...', 'green');
    })
    .catch((error) => {
      showPopup('Error regenerating route!', 'red');

      console.error('Error:', error);
    });
  }

  function getLiveMessage(internal_id) {
    fetchWithToken(`${API_ENDPOINT}/getmessage/${internal_id}/live`, token, setToken)
    .then(data => {
      if (!data) {
        throw new Error('Network response was not ok');
      }
      setLiveMessage(data.message);
      setShowModal(true);
      setLoading(prevLoading => ({ ...prevLoading, [activeRoute.id]: false }));

    })
    .catch((error) => {
      showPopup('Error getting live message!', 'red');
      setLoading(prevLoading => ({ ...prevLoading, [activeRoute.id]: false }));
      console.error('Error:', error);
    });
  }

  function getMessage(internal_id) {
    fetchWithToken(`${API_ENDPOINT}/getmessage/${internal_id}`, token, setToken)
    .then(data => {
      if (!data) {
        throw new Error('Network response was not ok');
      }
      setLiveMessage(data.message);
      setShowModal(true);
      setLoading(prevLoading => ({ ...prevLoading, [activeRoute.id]: false }));

    })
    .catch((error) => {
      showPopup('Error getting message!', 'red');
      setLoading(prevLoading => ({ ...prevLoading, [activeRoute.id]: false }));
      console.error('Error:', error);
    });
  }

  useEffect(() => {
    fetchWithToken(`${API_ENDPOINT}/getroutes`, token, setToken)
      .then(data => {
        if (data.error && data.error === 'Failed to get routes') {
          console.error('Error:', data.error);
          setAllRoutes([]);
        } else {
          setAllRoutes(data || []);
          if (data && data.length > 0) {
            // toggleActiveRoute(data[0]);
          }
        }
      })
      .catch(error => {
        console.error('Error fetching routes:', error);
        setAllRoutes([]);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]); 

  const filteredRoutes = allRoutes.filter(route => 
    route.custom_description.toLowerCase().includes(searchTerm.toLowerCase())
  );


  function formatAddresses(address) {
      const { address1, city, state, zip } = address;
      return [address1, city, state, zip].filter(Boolean).join(", ");
  }
  
  function displayRouteDetails(route) {

    const segments = [];
    if (route && route.geocode_addresses && route.optimal_stations) {
      // Start
      segments.push(<RouteSegment key="start" address={formatAddresses(route.geocode_addresses[0])} isOpen={true} type="start" />);
      segments.push(<hr key={`startHR`} />);
      // Stations

      Object.keys(route.optimal_stations).forEach(key => {
        route.optimal_stations[key].forEach((station, index) => {
          const segmentKey = `station-${key}-${index}`;
          segments.push(
            <div onClick={() => toggleSegment(segmentKey)} key={segmentKey}>
              <RouteSegment 
                key={`station-${key}-${index}`}
                // address={`Station at ${station.coordinates.lon}, ${station.coordinates.lat}`}
                address={`Station at ${formatAddresses(station.optimal_station)}`}
                type="station"
                fillUp={station.to_fill_up}
                total_fuel={station.total_fuel}
                distance={station.distance}
                isLastStation={index === route.optimal_stations[key].length - 1}
                pricePG={station.optimal_station.price}
                rank={station.rank}
                isOpen={openSegments[segmentKey]}
              />
            </div>
          );
          segments.push(<hr key={`HR-${key}-${index}`} />);
        });
        if (parseInt(key) + 1 === route.geocode_addresses.length - 1) {
          segments.push(<RouteSegment key="end" address={formatAddresses(route.geocode_addresses[parseInt(key) + 1])} type="end" />);
        } else {
          segments.push(<RouteSegment key={`start+${key}`} address={formatAddresses(route.geocode_addresses[parseInt(key) + 1])} type={`start`} />);
          segments.push(<hr key={`startHR+${key}`} />);
        }
      });
    }
    return segments;
  }


  return (
    <div className="main-content">
      <div className="routes-page">
        <div className="routes-list">
          <input
            type="text"
            placeholder="Search routes..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
          />
          {filteredRoutes.map(route => {
            let totalCost = 0;
            if (activeRoute && activeRoute.id === route.id) {
              Object.keys(activeRoute.optimal_stations).forEach(key => {
                activeRoute.optimal_stations[key].forEach(station => {
                  if (station.optimal_station.price && station.total_fuel) {
                    totalCost += station.to_fill_up.toFixed(0) * station.optimal_station.price.toFixed(3);
                  }
                });
            })}
            return (
              <div 
                key={route.id} 
                id={`route-card-${route.id}`}
                className={`route-card ${activeRoute && activeRoute.id === route.id ? 'active' : ''} ${!route.processed ? 'not-processed' : ''}`} 
                onClick={route.processed ? () => {
                  if (activeRoute && activeRoute.id === route.id) {
                    return;
                  }
                  toggleActiveRoute(route);
                } : null}
              >
               <div className="route-card-content">

                <h3>{route.custom_description}</h3>
                {activeRoute && activeRoute.id === route.id && <h5>Total Cost: ${totalCost.toFixed(0)}</h5> &&
                <div style={{ display: 'flex', justifyContent: 'center', gap: "10%" }}>
                  <button className={"button"} onClick={() => regenerateRoute(activeRoute.id)} key={`button-1-${route.id}`}>
                    Regenerate Travel Route
                  </button>
                  <button className={"button"}  onClick={handleOpenModal} key={`button-2-${route.id}`}>
                    Show Message
                  </button>
                  <button className={"button"}  onClick={handleLiveOpenModal} key={`button-3-${route.id}`}>
                    Show Live Message
                  </button>
                </div>
                }
                <p>Last Updated: {moment(route.timestamp * 1000).fromNow()}</p>
                {activeRoute && activeRoute.id === route.id && (
                  <div className="route-details">
                    {displayRouteDetails(activeRoute)}
                  </div>
                )}
                {loading[route.id] && (
                  <div className="loading-overlay">
                    <div className="spinner"></div>
                  </div>
                )}
              </div>
              </div>
            );
          })}
          
        </div>
        {activeRoute && (
          <RouteMap route={activeRoute.route_line} destinations={activeRoute.geocode_addresses} stations={activeRoute.optimal_stations} live_location={activeRoute.live_location} />
        )}
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={handleCloseModal}>
              &times;
            </span>
            <p>Generated Message </p>
            <textarea 
              disabled 
              style={{ 
                minWidth: '250px', 
                minHeight: '250px', 
                width: 'calc(100% - 40px)', // Subtract parent's left and right padding
                height: 'calc(100% - 40px)' // Subtract parent's top and bottom padding
              }}
              value={liveMessage} // Move the text content here
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default RoutesPage;
